import React, { useState, useEffect } from "react";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      fetch("https://aryy.my.id/projects.json")
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProjects(data);
          setLoading(false);
        })
        .catch((error) => console.error("Error:", error));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {projects.map((project) => (
        <div key={project.id} className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h3 className="card-title">{project.name}</h3>
            <p>{project.description}</p>
            <div className="card-actions justify-end">
              <a className="btn btn-primary" href={project.url}>
                View Project
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Projects;
