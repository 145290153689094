// components/About.js
import React from "react";

function About() {
  return (
    <div className="card w-full bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">About Me</h2>
        <p>
          I'm a passionate developer with experience in both web and mobile
          development...
        </p>
      </div>
    </div>
  );
}

export default About;
