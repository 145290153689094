// components/Skills.js
import React from "react";

function Skills() {
  const skills = ["Node.js", "React.js", "Next.js", "PHP", "Flutter"];

  return (
    <div className="card w-full bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">My Skills</h2>
        <div className="flex flex-wrap gap-2">
          {skills.map((skill, index) => (
            <div key={index} className="badge badge-primary">
              {skill}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Skills;
