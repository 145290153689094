// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <div className="App bg-base-100 min-h-screen">
        <Header />
        <main className="container mx-auto px-4 py-8">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>Home - My Portfolio</title>
                  </Helmet>
                  <Home />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Helmet>
                    <title>About - My Portfolio</title>
                  </Helmet>
                  <About />
                </>
              }
            />
            <Route
              path="/skills"
              element={
                <>
                  <Helmet>
                    <title>Skills - My Portfolio</title>
                  </Helmet>
                  <Skills />
                </>
              }
            />
            <Route
              path="/projects"
              element={
                <>
                  <Helmet>
                    <title>Projects - My Portfolio</title>
                  </Helmet>
                  <Projects />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Helmet>
                    <title>Contact - My Portfolio</title>
                  </Helmet>
                  <Contact />
                </>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
