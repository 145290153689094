// components/Home.js
import React from "react";

function Home() {
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Welcome to My Portfolio</h1>
          <p className="py-6">
            I'm a web and mobile developer specializing in Node.js, React.js,
            Next.js, PHP, and Flutter.
          </p>
          <button className="btn btn-primary">Get Started</button>
        </div>
      </div>
    </div>
  );
}

export default Home;
